.ant-modal-title{
    color: #ccc!important;
}
.ant-modal-content {
    background: rgba(33,38,43,0.9)!important;
    // input {
    //     background-color: rgba(33,38,43,0.9)!important;
    // }
}
.ant-modal-header {
    background: rgba(33,38,43,0.9)!important;
    border-bottom: 1px solid rgba(191,191,191,0.2)!important;
}

.anticon {
    color:#fff!important;
}

.ant-form-item-label > label{
    color:#E4E4E4!important;
}

.ant-btn {
    background: transparent;
    border-color: rgba(191,191,191,0.2);
    color: #fff;
    &:hover {
        color: #57B6FF;
        border-color: #57B6FF;
        background: transparent;
    }
}
.ant-btn-primary:hover {
    color: #FFF;
}

.ant-modal-footer {
    border-top:1px solid rgba(191, 191, 191, 0.2) !important;
    .ant-btn-primary {
        border-color: transparent;
    }
}