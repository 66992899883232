@import './reset.scss';

@import "component/antd/Input/Input.scss";
@import 'component/antd/Select/Select.scss';
@import 'component/antd/DatePicker/DatePicker.scss';
@import 'component/antd/Radio/Radio.scss';
@import 'component/antd/Button/Button.scss';
@import 'component/antd/Modal/Modal.scss';
@import 'component/antd/Table/Table.scss';
@import 'component/antd/Pagination/Pagination.scss';

// @font-face {
//     font-family: 'PingFang SC';
//     src: url('../media/PingFang Regular.ttf');
// }

* {
    box-sizing: border-box;
    // font-family: Microsoft YaHei, PingFang SC;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;

    :not(input) {
        user-select: none;
    }
}

img {
    width: 100%;
    height: auto;
    vertical-align: top !important;
}

input:-webkit-autofill {
    -webkit-text-fill-color: #fff !important;
    -webkit-text-fill-color: #fff;
    transition: background-color 5000s ease-in-out 0s;
}