.ant-pagination-item {
  color: #ccc !important;
  background-color: transparent !important;
  border: 1px solid #646262 !important;

  a {
    color: #ccc !important;
  }
}

.ant-pagination-item-active {
  background-color: #39A2F7 !important;
  border-color: #39A2F7 !important;

  a {
    color: #fff !important
  }
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  color: #ccc !important;
  background-color: transparent !important;
  border: 1px solid #646262 !important;
}

.ant-input {
  border: 1px solid #646262 !important;
}