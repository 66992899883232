/* Radio.Group */
.ant-radio-button-wrapper {
  color: #999999!important;
  background: transparent !important;
  border-color: #646262 !important;

  &:hover {
    color: #FFFFFF !important;
  }
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #FFFFFF !important;
  background: #39A2F7 !important;
  border-color: #39A2F7 !important;
  box-shadow: -1px 0 0 0 #39A2F7 !important;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: #646262 !important;
}

.ant-radio-wrapper{
  white-space: normal!important;
  width: 128px;
}