/* Input */
.ant-input {
  color: rgba(255, 255, 255, 0.6) !important;
  background-color: transparent !important;
  border: 1px solid #4B4B4B !important;
  border-radius: 4px !important;
}

/* InputNumber */
.ant-input-number {
  color: rgba(255, 255, 255, 0.6) !important;
  background-color: transparent !important;
  border: 1px solid #4B4B4B !important;
  border-radius: 4px !important;

  &:focus {
    border-color: #4B4B4B !important;
    box-shadow: none !important;
  }

  .ant-input-number-handler-wrap {
    background: transparent !important;
    border-left: 1px solid #4B4B4B !important;

    .ant-input-number-handler-up-inner,
    .ant-input-number-handler-down-inner {
      color: #9D9D9D !important;
    }

    .ant-input-number-handler-down {
      border-top: 1px solid #4B4B4B !important;
    }
  }
}