/* DatePicker */
.ant-calendar-picker {
  .ant-calendar-range-picker-input {
    color: rgba(236, 236, 236, 0.6) !important;
  }

  .ant-calendar-range-picker-separator {
    color: rgba(236, 236, 236, 0.6) !important;
  }

  .ant-calendar-picker-clear {
    color: rgba(236, 236, 236, 0.6) !important;
    background-color: transparent !important;
  }

  &:hover .anticon-calendar {
    opacity: 0;
  }
}

.ant-calendar-picker-container {
  color: rgba(255, 255, 255, 0.75) !important;

  .ant-calendar {
    // background-color: rgba(33, 38, 43, 0.8) !important;
    background-color: rgba(33, 38, 43, 1) !important;
    border: none !important;
    border-radius: 4px !important;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15) !important;

    .ant-calendar-input-wrap {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);

      .ant-calendar-input {
        color: rgba(255, 255, 255, 0.65) !important;
        background-color: transparent !important;
      }
    }

    .ant-calendar-footer {
      .ant-calendar-time-picker-btn {
        color: #39A2F7 !important
      }
      .ant-calendar-ok-btn {
        background-color: #39A2F7 !important;
        border:1px solid #39A2F7 !important;
      }
    }

    .ant-calendar-range-middle {
      color: rgba(255, 255, 255, 0.65) !important;
    }

    .ant-calendar-header {
      .ant-calendar-prev-year-btn,.ant-calendar-prev-month-btn,.ant-calendar-next-month-btn,.ant-calendar-next-year-btn{
        color: rgba(255, 255, 255, 0.85) !important;
      }
      .ant-calendar-ym-select {

        .ant-calendar-year-select,
        .ant-calendar-month-select,
        .ant-calendar-day-select {
          font-size: 14px !important;
          font-weight: 400 !important;
          color: rgba(255, 255, 255, 0.85) !important;
        }
      }
    }

    li.ant-calendar-time-picker-select-option-selected {
      background: #fff!important;
      color:#000;
    }
    .ant-calendar-time-picker-combobox {
      background-color: #000!important;
      opacity: 0.8;
    }
    .ant-calendar-body {
      border-top: 1px solid rgba(255, 255, 255, 0.2) !important;

      .ant-calendar-date {
        color: rgba(255, 255, 255, 0.75) !important;

        &:hover {
          background-color: #39A2F7 !important;
        }
      }

      .ant-calendar-last-month-cell .ant-calendar-date,
      .ant-calendar-next-month-btn-day .ant-calendar-date,
      .ant-calendar-last-month-cell .ant-calendar-date:hover,
      .ant-calendar-next-month-btn-day .ant-calendar-date:hover {
        color: rgba(255, 255, 255, 0.35) !important;
      }

      .ant-calendar-disabled-cell .ant-calendar-date{
        background:rgba(0,0,0,0.1);
      }

      .ant-calendar-selected-date .ant-calendar-date,
      .ant-calendar-selected-start-date .ant-calendar-date,
      .ant-calendar-selected-end-date .ant-calendar-date {
        background-color: #39A2F7 !important;
      }

      .ant-calendar-in-range-cell::before {
        background: rgba(230, 247, 255, 0.7) !important;
      }

      .ant-calendar-today .ant-calendar-date {
        border: 1px solid #1890FF !important;
      }
    }
  }
}