.ant-table {
  border-top: 1px solid #666;
  //border-left: 1px solid #666;
  color: #ccc !important;
  border-radius: 8px;
  overflow: hidden;
}

.ant-table-thead>tr:first-child>th:first-child {
  border-top-left-radius: 8px !important;
  border-left: 1px solid #666;
  //border-bottom: 1px solid #666!important;
}

.ant-table-tbody>tr>td:first-child {
  border-left: 1px solid #666;
  //border-bottom-left-radius: 8px;
}

.ant-table-tbody>tr:last-child>td:first-child {
  border-bottom-left-radius: 8px;
}

.ant-table-tbody>tr:last-child>td:last-child {
  border-bottom-right-radius: 8px;
}

.ant-table-thead>tr:first-child>th:last-child {
  border-top-right-radius: 8px !important;
}

.ant-table-bordered .ant-table-body>table {
  border: 0 !important;
}

.ant-table-thead>tr>th {
  background-color: rgba(78, 78, 78, 0.33) !important;
  color: rgba(255, 255, 255, 0.8) !important;
  // border: 1px solid #666;
  border-right: 1px solid #666 !important;
  border-bottom: 1px solid #666 !important;
}

.ant-table-tbody>tr>td {
  color: rgba(255, 255, 255, 0.7) !important;
  // border: 1px solid #666;
  border-right: 1px solid #666 !important;
  border-bottom: 1px solid #666 !important;
}

.ant-table-tbody>tr:hover>td {
  color: #343356;
}

.ant-table-placeholder {
  background: transparent !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid #666 !important;
}

.ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
.ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td,
.ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td,
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
  background: rgba(57, 162, 247, .3) !important;
}

.ant-table-small {
  border: none !important;
  border-top: 1px solid #666 !important;
  border-radius: 0px !important;

  .ant-table-body {
    margin: 0 !important;
  }
}