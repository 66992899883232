/* Select */
.ant-select {
  color: rgba(255, 255, 255, 0.8) !important;

  .ant-select-selection {
    background-color: transparent !important;
    border: 1px solid #4B4B4B !important;
  }

  .ant-select-selection-selected-value {
    color: rgba(255, 255, 255, 0.8) !important;
  }
}

.ant-select-dropdown {
  color: rgba(255, 255, 255, 0.8) !important;
  background-color: #21262B !important;
  border-radius: 4px !important;
  outline: none !important;
  box-shadow: none !important;

  .ant-select-dropdown-menu-item {
    color: rgba(255, 255, 255, 0.8) !important;

    &:hover:not(.ant-select-dropdown-menu-item-disabled) {
      background-color: rgba(57, 162, 247, 0.6) !important;
      border-radius: 4px !important;
    }
  }

  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: rgba(57, 162, 247, 0.6) !important;
    //background-color: #21262B !important;
  }

  .ant-select-dropdown-menu-item-selected {
    background-color: rgba(57, 162, 247, 0.6) !important;
  }
}

// .ant-select-dropdown--multiple {
//   max-width: 100px;
// }

.ant-select-selection--multiple .ant-select-selection__choice {
  //color: #999999 !important;
  color: rgba(255, 255, 255, 0.8) !important;
  background-color: transparent !important;
  //border: 1px solid #646262 !important;
  border:none!important;
}